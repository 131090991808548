import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useRouter } from '@core/utils/utils';

export default function useRenew(props, emit, refFormObserver) {
  const toastification = toast();

  const { route } = useRouter();
  const planSlug = route.value.params.slug;
  const planId = planSlug.substring(planSlug.lastIndexOf('-') + 1);

  const refForm = ref(refFormObserver);
  const blankItem = {
    planId,
    month: 12,
    moreSlots: 0,
    vat: false,
    name: '',
    company: '',
    email: '',
    phone: '',
    taxNumber: '',
    address: '',
    paymentMethod: 1,
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };
  const plan = ref(null);

  const amount = computed(() => {
    if (!plan) return 0;
    const year = parseInt(itemLocal.value.month / 12, 10);
    const month = itemLocal.value.month - 12 * year;

    const totalPlanPrice = year * plan.value.annualPrice + month * plan.value.monthlyPrice;
    const totalBuyMorePrice = plan.value.blockPrice * (itemLocal.value.moreSlots / 10) * itemLocal.value.month;
    return totalBuyMorePrice + totalPlanPrice;
  });

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchPlanDetail = () => {
    store.dispatch('plan/getPlanDetail', planId)
      .then(response => {
        plan.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onSubmit = async () => {
    store.dispatch('order/createOrder', { ...itemLocal.value, amount: amount.value }).then(response => {
      const url = response.data;
      if (url && url.length > 0) {
        window.location.href = url;
      }
    }).catch(error => {
      toastification.showToastError(error, refForm.value);
    });
  };

  return {
    refForm,
    itemLocal,
    plan,
    amount,
    resetItemLocal,
    resetModal,
    onSubmit,
    fetchPlanDetail,
  };
}
